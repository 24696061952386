import React, {useEffect, useState} from 'react';
import Preloader from "../Preloader";
import {PREMIUM} from "../../const/tier";
import {
  NoOfferCongrats,
  OfferCongrats,
  PremiumCommunityOffersCongrats,
  PremiumOnlyOfferMessage
} from "./index";
import {getCallbackVetrewards, getClientConfig, getVetrewards} from "../../actions/hydra";
import checkRedemptionsAccess from "../../actions/checkRedemptionAccess";
import Error from "../Errors";

const CallbackScreen = (props) => {

  const {
    cancelProcessing,
    clientId,
    consentRedirectUrl,
    endProcessing,
    userData,
  } = props;

  // We assume that only one exclusive and/or community offer exists.
  const [exclusiveOffer, setExclusiveOffer] = useState([])
  const [communityOffer, setCommunityOffer] = useState([])

  const [clientName, setClientName] = useState()
  const [clientLogo, setClientLogo] = useState()
  const [offers, setOffers] = useState()
  const [offersLoaded, setOffersLoaded] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)

  const [error, setError] = useState()

  useEffect(() => {
    getClientConfig(clientId)
      .then(data => {
        setClientName(data?.config?.name)
        setClientLogo(data?.config?.logo)
        setOffers(data?.offers)
        setDataLoaded(true)
      })
      .catch(error => console.error(error))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userData && dataLoaded) {
      if (offers) {
        // Get user audience.
        const audience = userData?.profile?.audience?.[0]
        const tier = userData?.profile?.tier
        if (!audience) {
          setError("Missing audience")
        }
        if (!tier) {
          setError("Missing tier")
        }
        const exclusive = offers.filter(item => {
          return checkRedemptionsAccess(item, audience, 'premium') &&
            !checkRedemptionsAccess(item, audience, 'basic')
        })
        const community = offers.filter(item => checkRedemptionsAccess(item, audience, tier))
        // Set first community and exclusive offers.
        setExclusiveOffer(exclusive?.[0])
        setCommunityOffer(community?.[0])
        setOffersLoaded(true)
      }
      else {
        // Skip offers page if no offers available.
        endProcessing()
      }
    }
    // eslint-disable-next-line
  }, [offers, userData, dataLoaded])

  return (
    <>
      {error
        ? <Error message={error}/>
        : !userData || !offersLoaded
          ? <Preloader title="Loading Congratulations screen"/>
          // If we have both community and premium offers and user is not premium.
          : (communityOffer?.title && exclusiveOffer?.title && userData?.profile?.tier !== PREMIUM)
            ? <PremiumCommunityOffersCongrats
              name={clientName}
              logo={clientLogo}
              communityOfferTitle={communityOffer?.title}
              exclusiveOfferTitle={exclusiveOffer?.title}
              endProcessing={endProcessing}
              getVetrewards={() => consentRedirectUrl ? getVetrewards(consentRedirectUrl) : getCallbackVetrewards(clientId)}
            />
            // If we have only community offer or only exclusive and user is premium.
            : (communityOffer?.title || (exclusiveOffer?.title && userData?.profile?.tier === PREMIUM))
              ? <OfferCongrats
                name={clientName}
                logo={clientLogo}
                offerTitle={userData?.profile?.tier !== PREMIUM ? communityOffer?.title : (exclusiveOffer?.title ?? communityOffer?.title)}
                endProcessing={endProcessing}
              />
              // If we have only exclusive offer and user is not premium (Upgrade path).
              : (exclusiveOffer?.title && userData?.profile?.tier !== PREMIUM)
                ? <PremiumOnlyOfferMessage
                  name={clientName}
                  logo={clientLogo}
                  offerTitle={exclusiveOffer?.title}
                  getVetrewards={() => consentRedirectUrl ? getVetrewards(consentRedirectUrl) : getCallbackVetrewards(clientId)}
                  cancelProcessing={cancelProcessing}
                />
                // There is no offers at all.
                : <NoOfferCongrats
                  name={clientName}
                  logo={clientLogo}
                  endProcessing={endProcessing}
                />

      }
    </>
  );
};

export default CallbackScreen;